<template>
  <div class="" v-if="quote && getVersionQuote">
    <TopBarTitleComponent title="Cotizaciones" :showBackIcon="true">
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <!-- <button class="btn md__btn-third-secondary mr-4">Ver Adjuntos</button> -->
          <el-dropdown
            @command="handleCommand"
            class="dropdown-theme-gray-outline d-flex align-items-center ml-4"
          >
            <span class="el-dropdown-link">
              <span class="ml-2">Opciones</span>
              <i class="el-icon-arrow-down el-icon--right mr-2"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <template v-if="isStatusDraft || isStatusPending">
                <el-dropdown-item command="quote-edit"
                  ><img :src="iconEdit" alt="" /> Editar</el-dropdown-item
                >
                <el-dropdown-item
                  command="quote-change-status-pending"
                  v-if="isStatusDraft"
                  ><img :src="iconDisapproved" alt="" /> Cambiar a
                  pendiente</el-dropdown-item
                >
                <el-dropdown-item
                  command="quote-change-status-deny"
                  v-if="isStatusPending"
                  ><img :src="iconDisapproved" alt="" /> Cambiar no
                  aprobada</el-dropdown-item
                >
                <el-dropdown-item command="quote-delete" v-if="isStatusDraft"
                  ><img :src="iconDelete" alt="" /> Eliminar</el-dropdown-item
                >
              </template>
              <el-dropdown-item command="quote-send">
                <img :src="iconSend" alt="" /> Enviar
                documento</el-dropdown-item
              >
              <el-dropdown-item command="quote-history-email">
                <img :src="iconHistory" alt="" /> Historial
                envio</el-dropdown-item
              >
              <el-dropdown-item command="quote-download">
                <img :src="iconDownload" alt="" /> Descargar
                PDF</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </TopBarTitleComponent>
    <b-container fluid class="pb-4 px-lg-4">
      <b-row>
        <b-col>
          <AlertInfoComponent
            title="Ventas"
            :description="apiResponse.message"
            v-if="apiResponse.message"
            @alertClose="onAlertClose"
            class="mb-4"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <QuoteListVersionsComponent
            :quote="quote"
            @onClickVersion="handleOnClickVersion"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div
            class="card box-shadow card-quotes-view py-4"
            v-if="apiResponse.success"
          >
            <div class="card-body">
              <div class="card-quotes-view-header">
                <b-row>
                  <b-col xs="12" sm="12" md="12" lg="6">
                    <div class="card-quotes-view-logo mb-4">
                      <b-row>
                        <b-col xs="12" sm="12" md="5" lg="4">
                          <div class="logo">
                            <img
                              v-if="getUser.user_business.business_logo"
                              :src="getUser.user_business.business_logo"
                              class="image"
                              id="image-business"
                              alt="Empresa"
                              width="200px"
                            />
                          </div>
                        </b-col>
                        <b-col xs="12" sm="12" md="7" lg="8">
                          <div class="info">
                            <p class="mb-2 text-color-secondary font-bold">
                              Versión: {{ getVersionQuote.version_number }}
                            </p>
                            <h4 class="h4 font-bold m-0">
                              N° {{ quote.code }}
                            </h4>
                            <h2 class="h2 font-bold m-0">
                              {{ getVersionQuote.name }}
                            </h2>
                            <p class="font-semi-bold mt-2">
                              RUC: {{ getUser.user_business.business_ruc }}
                            </p>
                            <p class="font-semi-bold">
                              {{ getUser.user_business.business_address }}
                            </p>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col xs="12" sm="12" md="12" lg="6">
                    <div class="card-quotes-view-info">
                      <!-- <div
                        class="header-title-section bg-color-primary py-0 mb-4 border-radius"
                      >
                        <h2 class="h2 font-semi-bold text-center py-2">
                          Cotización
                        </h2>
                      </div> -->
                      <b-row>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Emisión</label>
                          <p class="m-0">{{ getVersionQuote.date_at }}</p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Validez</label>
                          <p class="m-0">{{ getVersionQuote.validate_at }}</p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block mb-2"
                            >Status</label
                          >
                          <StatusReadDocumentsComponent
                            :status="quote.status"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="mt-4">
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Contacto</label>
                          <p class="m-0">{{ contactName }}</p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Dirección</label
                          >
                          <p class="m-0">{{ quote.client.address }}</p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Teléfono Contacto</label
                          >
                          <p class="m-0">
                            Telf: {{ getUser.user_business.business_phone }}
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="card-quotes-view-body mt-5">
                <div class="card-quotes-view-body-products">
                  <div class="card-quotes-view-table table-items">
                    <b-row class="table-items-header">
                      <b-col xs="12" sm="12" md="1">
                        <h6 class="h6 font-bold mb-0 ml-2">Código</h6>
                      </b-col>
                      <b-col xs="12" sm="12" md="5">
                        <h6 class="h6 font-bold mb-0">Detalle</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Cantidad</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Precio U.</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Subtotal</h6>
                      </b-col>
                    </b-row>
                  </div>

                  <b-row
                    class="mt-4 d-flex"
                    v-for="(product, index) in getVersionQuote.products"
                    :key="index"
                  >
                    <b-col xs="12" sm="12" md="1">
                      <p class="m-0 ml-2">{{ product.product.code }}</p>
                    </b-col>
                    <b-col xs="12" sm="12" md="5">
                      <p class="m-0">
                        <strong>{{ product.product.name }}</strong> <br />
                        <br />
                        <span
                          v-html="product.product_detail"
                          class="text-pre-wrap"
                        ></span>
                      </p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">{{ product.product_quantity }}</p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">${{ product.product_amount }}</p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">
                        ${{
                          handleCalculateSubTotal(
                            product.product_quantity,
                            product.product_amount
                          )
                        }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <b-row>
                  <b-col xs="12" sm="12" md="6">
                    <div class="card-quotes-view-aditionals bg-color-gray p-4">
                      <p class="font-semi-bold">Especificaciones Adicionales</p>
                      <b-row
                        class="my-2"
                        v-for="(specification,
                        index) in getVersionQuote.additional_specifications"
                        :key="index"
                      >
                        <b-col xs="10" sm="10" md="10"
                          >- {{ specification }}</b-col
                        >
                      </b-row>
                    </div>
                    <div
                      class="card-quotes-view-method-pay mt-4 p-4 d-flex align-items-baseline"
                    >
                      <p class="font-semi-bold mr-4">Forma de pago</p>
                      <section v-if="getVersionQuote.method_payment === 1">
                        <el-radio
                          v-model="getVersionQuote.method_payment"
                          :label="1"
                          >De contado</el-radio
                        >
                      </section>

                      <section v-if="getVersionQuote.method_payment === 2">
                        <el-radio
                          v-model="getVersionQuote.method_payment"
                          :label="2"
                          >Crédito
                        </el-radio>
                        <p class="mt-2">
                          {{ getLimitCredit.label }}
                          {{ getVersionQuote.limit_credit_at }}
                        </p>
                      </section>

                      <section v-if="getVersionQuote.method_payment === 3">
                        <el-radio
                          v-model="getVersionQuote.method_payment"
                          :label="3"
                          >Anticipo / Contra entrega
                        </el-radio>
                        <p class="mt-2">
                          {{ getVersionQuote.observation_advance }}
                        </p>
                      </section>
                    </div>
                  </b-col>
                  <b-col xs="12" sm="12" md="4" offset-md="2">
                    <DocumentCalculator
                      readOnly
                      :setProducts="getVersionQuote.products"
                      :setDisc="getVersionQuote.discount"
                    />
                  </b-col>
                </b-row>
              </div>
              <!-- <div
                class="card-quotes-view-footer bg-color-primary py-0 border-radius mt-4"
              >
                <h3 class="h3 font-weight-bold text-center py-3">
                  www.mydessk.com
                </h3>
              </div> -->
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <WrapperSendDocumentDrawer
      @onDocumentSend="onDocumentSend"
      @onCloseDrawer="onCloseDocumentSendDrawer"
      :visibleDocumentSend="visibleDocumentSend"
      :toMails="toMails"
    />

    <DocumentSendEmailHistory
      @onCloseDrawer="onCloseDocumentSendEmailHistory"
      :visibleDocumentHistoryEmail="visibleDocumentHistoryEmail"
      :emails="getVersionQuote.user_traking_email_document"
    />
  </div>
</template>

<script>
import AlertInfoComponent from "@/components/Alerts/Info";
import TopBarTitleComponent from "../../../../components/TopBarTitle";
import QuoteListVersionsComponent from "../components/QuoteListVersions";
import WrapperSendDocumentDrawer from "../../../../components/WrapperSendDocumentDrawer";
import StatusReadDocumentsComponent from "../../../../components/StatusReadDocuments";
import DocumentCalculator from "../../../components/DocumentCalculator";
import DocumentSendEmailHistory from "../../../components/DocumentSendEmailHistory";

import quoteService from "../services/quoteService";
import { parseFloatFormat } from "@/assets/utils/format";
import { limitCredits } from "../assets/resources";
import {
  icoMore,
  iconDownload,
  iconHistory,
  iconSend,
  iconDelete,
  iconDisapproved,
  iconEdit
} from "@/services/resources";
import { downloadFile } from "@/assets/utils/files";

export default {
  name: "QuoteViewPage",
  data: () => ({
    icoMore,
    apiResponse: {
      success: false,
      message: null
    },
    idVersion: null,
    quote: null,
    limitCredits,
    iconDownload,
    iconHistory,
    iconSend,
    iconDelete,
    iconDisapproved,
    iconEdit,
    visibleDocumentSend: false,
    visibleDocumentHistoryEmail: false
  }),
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
    getLimitCredit() {
      return limitCredits.find(
        limit => this.getVersionQuote.limit_credit === limit.value
      );
    },
    getVersionQuote() {
      return this.quote.versions.find(v => this.idVersion === v.id);
    },
    isStatusDraft() {
      return this.quote.status === "draft";
    },
    isStatusPending() {
      return this.quote.status === "pending";
    },
    contactName() {
      return this.quote.contact
        ? this.quote.contact.name
        : this.quote.client.name;
    },
    toMails() {
      const { client, contact } = this.quote;
      if (contact && contact.email) return [contact.email];
      else if (client && client.email) return [client.email];
      return [];
    }
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    handleCalculateSubTotal(quantity, price) {
      return parseFloatFormat(quantity * price);
    },
    goPage(name) {
      this.$router.push({ name });
    },
    async getQuote(quoteId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await quoteService.getQuoteId(quoteId).finally(() => {
          this.$store.dispatch("toggleRequestLoading");
        });
        const { success, quote } = response.data;
        if (success) {
          this.quote = quote;
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    },

    async updateQuoteStatus(status) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await quoteService
          .updateQuoteStatus(this.quote.id, {
            status
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.handleStatusPendingQuote();
          this.$notifications.success({
            message
          });
          this.quote.status = status;
        }
      } catch (error) {
        return false;
      }
    },
    handleOnClickVersion(version) {
      this.idVersion = version.id;
    },
    onParseFloatFormat(value) {
      return parseFloatFormat(value);
    },
    onAlertClose() {
      this.toggleApiResponse("message", "");
    },
    handleCommand(command) {
      switch (command) {
        case "quote-edit":
          this.goPage("sales.quotes.edit");
          break;
        case "quote-send":
          this.visibleDocumentSend = true;
          break;
        case "quote-history-email":
          this.visibleDocumentHistoryEmail = true;
          break;
        case "quote-download":
          this.quoteDownload();
          break;
        case "quote-delete":
          this.quoteDelete();
          break;
        case "quote-change-status-pending":
          this.updateQuoteStatus("pending");
          break;
        case "quote-change-status-deny":
          this.updateQuoteStatus("deny");
          break;
      }
    },
    onCloseDocumentSendDrawer() {
      this.visibleDocumentSend = false;
    },
    onCloseDocumentSendEmailHistory() {
      this.visibleDocumentHistoryEmail = false;
    },
    onDocumentSend({ data }) {
      data.append("quote_id", this.quote.id);
      data.append("quote_version_id", this.idVersion);

      this.sendQuoteEmail({ data });
    },
    async quoteDownload() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await quoteService
          .downloadQuote({
            quoteId: this.quote.id,
            quoteVersionId: this.idVersion
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        if (response) {
          downloadFile(response.data, `Cotización - #${this.quote.code}`);
        }
      } catch (error) {
        return false;
      }
    },
    async quoteDelete() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await quoteService
          .deleteQuoteId(this.quote.id)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });

        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
          this.$emit("onUpdate");
        }
      } catch (error) {
        return false;
      }
    },
    async sendQuoteEmail({ data }) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await quoteService
          .sendQuoteEmail({
            data
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message, data: quote } = response.data;
        if (success) {
          this.quote = quote;
          this.visibleDocumentSend = false;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    TopBarTitleComponent,
    AlertInfoComponent,
    QuoteListVersionsComponent,
    StatusReadDocumentsComponent,
    WrapperSendDocumentDrawer,
    DocumentCalculator,
    DocumentSendEmailHistory
  },
  props: {
    query: {}
  },
  mounted() {
    if (this.query && this.query.quoteSendEmail)
      this.visibleDocumentSend = true;
    const { id: quoteId, idVersion } = this.$route.params;
    this.idVersion = parseInt(idVersion);
    if (quoteId && idVersion) this.getQuote(quoteId);
  }
};
</script>

<style lang="scss" scoped>
.card-quotes-view {
  .card-quotes-view-header {
    .card-quotes-view-logo {
      p {
        margin: 0;
      }
    }
  }
  .card-quotes-view-body-products {
    min-height: 300px;
  }
}
</style>
