<template>
  <el-drawer
    title=""
    :visible.sync="visibleDrawer"
    :with-header="false"
    custom-class="wrapper__document-send-email-history"
    @closed="onCloseDrawer"
    size="100%"
  >
    <section class="p-4">
      <h3 class="h3 font-semi-bold text-break text-color-primary mb-4">
        Historial de envío de documento
      </h3>

      <div class="block">
        <el-timeline :reverse="true" v-if="historyEmails.length">
          <el-timeline-item
            placement="top"
            v-for="(email, index) in historyEmails"
            :key="index"
            :timestamp="email.created_at"
          >
            <el-card>
              <p class="m-0"><strong>TO: </strong> {{ email.to }}</p>
              <p class="m-0"><strong>CC: </strong> {{ email.cc }}</p>
              <p class="my-3" v-html="email.message"></p>
              <p class="text-right">
                <strong>{{ email.files }}</strong>
              </p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <EmptyData v-else />
      </div>
    </section>
  </el-drawer>
</template>

<script>
import EmptyData from "@/components/General/EmptyData";

export default {
  name: "DocumentSendEmailHistory",

  data() {
    return {
      visibleDrawer: this.visibleDocumentHistoryEmail,
      historyEmails: this.emails
    };
  },
  watch: {
    visibleDocumentHistoryEmail(value) {
      this.visibleDrawer = value;
    },
    emails(value) {
      this.historyEmails = value;
    }
  },
  methods: {
    onCloseDrawer() {
      this.$emit("onCloseDrawer");
    }
  },
  components: { EmptyData },
  props: {
    visibleDocumentHistoryEmail: {
      default: false
    },
    emails: {
      type: Array,
      default: () => []
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped></style>
